import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import { FileDescription } from './FileDescription';
export const TransitionTooltip = ({ transitionsHistory, fileSize, fileType }) => {
    return (_jsx(Tooltip, { placement: "right", title: _jsx(Grid, { container: true, sx: {
                pt: '16px',
                pb: '16px',
                '&>.MuiGrid-root:last-child': { mb: '0px' },
                '&>.MuiGrid-root': { mb: '8px' },
            }, children: _jsxs(_Fragment, { children: [_jsx(Typography, { sx: { mb: '8px' }, children: _jsx(FileDescription, { fileSize: fileSize, fileType: fileType }) }), transitionsHistory.map((transition, index) => (_jsxs(Grid, { container: true, item: true, justifyContent: "space-between", children: [_jsx(Typography, { sx: { mr: '16px' }, children: transition.status.label }), _jsx(Typography, { children: format(transition.triggeredAt, 'dd/MM/yyyy HH:mm') })] }, index)))] }) }), children: _jsx(IconButton, { size: "small", disableRipple: true, children: _jsx(HelpOutlineIcon, { fontSize: "small" }) }) }));
};
