export const documentTypesModelMapper = (documentTypes) => {
    return documentTypes.sort((a, b) => {
        const getPriority = (documentType) => {
            if (documentType.toBeFilled && documentType.required) {
                return 1;
            }
            if (documentType.toBeUploaded && documentType.required) {
                return 2;
            }
            if (documentType.toBeUploaded && !documentType.required) {
                return 3;
            }
            return 4;
        };
        return getPriority(a) - getPriority(b);
    });
};
