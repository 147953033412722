import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
export const DescriptionDot = () => {
    return (_jsx(Box, { component: "span", sx: {
            display: 'inline-block',
            marginLeft: '4px',
            marginRight: '4px',
            marginBottom: '2px',
            height: '4px',
            width: '4px',
            borderRadius: '50%',
            backgroundColor: '#899294',
        } }));
};
