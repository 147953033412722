import { cqsDetailStateModelMapper } from './cqsDetailStateModelMapper';
import { mortgageDetailStateModelMapper } from './mortgageDetailStateModelMapper';
export const detailStateModelMapper = (paperwork, permissions, products) => {
    switch (paperwork.productTypology) {
        case 'cqs':
            return cqsDetailStateModelMapper(paperwork.data, permissions);
        case 'mortgage':
            return mortgageDetailStateModelMapper(paperwork.data, permissions, products);
        default:
            return null;
    }
};
