import * as t from 'io-ts';
import { documentation } from './Documentation';
import { nextAction } from './NextAction';
import { productTypology } from './ProductTypology';
import { transition } from './Transition';
export const data = t.intersection([
    t.type({
        nome: t.string,
        cognome: t.string,
        email: t.string,
        codice_fiscale: t.string,
        rubinetto: t.string,
        classe_rubinetto: t.string,
        telefono_cellulare: t.string,
        tipo_di_prodotto_long: t.string,
    }),
    t.partial({
        note: t.union([t.null, t.string]),
        sesso: t.union([t.null, t.string]),
        store: t.union([t.null, t.string]),
        finalita: t.union([t.null, t.string]),
        istituto: t.union([t.null, t.string]),
        in_carico_a: t.union([t.null, t.string]),
        professione: t.union([t.null, t.string]),
        cittadinanza: t.string,
        stato_civile: t.string,
        rilasciato_da: t.union([t.null, t.string]),
        cognome_e_nome: t.union([t.null, t.string]),
        n_di_documento: t.union([t.null, t.string]),
        telefono_fisso: t.union([t.null, t.string]),
        _schema_version: t.number,
        data_di_nascita: t.union([t.null, t.string]),
        tipo_di_azienda: t.union([t.null, t.string]),
        cap_di_residenza: t.union([t.null, t.string]),
        data_di_rilascio: t.union([t.null, t.string]),
        data_di_scadenza: t.union([t.null, t.string]),
        luogo_di_nascita: t.union([t.null, t.string]),
        richiedente_nome: t.union([t.null, t.string]),
        richiedente_sign: t.union([t.null, t.string]),
        tipo_di_prodotto: t.union([t.null, t.string]),
        comune_di_nascita: t.union([t.null, t.string]),
        created_at_format: t.union([t.string, t.null, t.string]),
        importo_richiesto: t.union([t.null, t.string, t.number]),
        luogo_di_rilascio: t.union([t.null, t.string]),
        tipo_di_documento: t.union([t.string, t.null, t.string]),
        data_firma_mandato: t.union([t.null, t.string]),
        luogo_di_residenza: t.union([t.null, t.string]),
        comune_di_residenza: t.union([t.null, t.string]),
        current_date_format: t.union([t.string, t.null, t.string]),
        numero_di_mensilita: t.union([t.number, t.null, t.string]),
        richiedente_cognome: t.union([t.null, t.string]),
        anzianita_lavorativa: t.union([t.number, t.null, t.string]),
        codice_fiscale_upper: t.union([t.null, t.string]),
        provincia_di_nascita: t.union([t.null, t.string]),
        data_scadenza_mandato: t.union([t.null, t.string]),
        importo_richiesto_eur: t.union([t.null, t.string]),
        localita_di_residenza: t.union([t.null, t.string]),
        reddito_mensile_netto: t.union([t.number, t.null, t.string]),
        data_di_nascita_format: t.union([t.string, t.null, t.string]),
        indirizzo_di_residenza: t.union([t.null, t.string]),
        provincia_di_residenza: t.union([t.null, t.string]),
        data_di_rilascio_format: t.union([t.string, t.null, t.string]),
        data_di_scadenza_format: t.union([t.string, t.null, t.string]),
        cessione_del_quinto_rata: t.union([t.number, t.null, t.string]),
        cessione_del_quinto_taeg: t.union([t.number, t.null, t.string]),
        importo_richiesto_lettere: t.union([t.null, t.string]),
        salesforce_quote_reference: t.union([t.null, t.string]),
        componenti_nucleo_familiare: t.union([t.null, t.number, t.string]),
        anticipo_tfs_monte_interessi: t.union([t.number, t.null, t.string]),
        cessione_del_quinto_finalita: t.union([t.null, t.string]),
        cessione_del_quinto_montante: t.union([t.number, t.null, t.string]),
        cessione_del_quinto_prodotto: t.union([t.null, t.string]),
        mutuo_percentuale_mediazione: t.union([t.null, t.string]),
        partita_iva_datore_di_lavoro: t.union([t.null, t.string]),
        data_erogazione_finanziamento: t.union([t.null, t.string]),
        cessione_del_quinto_durata_mesi: t.union([t.number, t.null, t.string]),
        cessione_del_quinto_finanziaria: t.union([t.null, t.string]),
        mezzo_di_pagamento_finanziamento: t.union([t.null, t.string]),
        richiedente_codice_fiscale_upper: t.union([t.null, t.string]),
        cessione_del_quinto_delta_montante: t.union([t.number, t.null, t.string]),
        cessione_del_quinto_monte_interessi: t.union([t.number, t.null, t.string]),
        cessione_del_quinto_punti_applicati: t.union([t.number, t.null, t.string]),
        data_erogazione_finanziamento_format: t.union([t.null, t.string]),
        cessione_del_quinto_importo_richiesto: t.union([t.number, t.null, t.string]),
        cessione_del_quinto_tipo_di_dipendente: t.union([t.null, t.string]),
        cessione_del_quinto_tipo_di_operazione: t.union([t.null, t.string]),
        cessione_del_quinto_anticipo_tfs_finalita: t.union([t.null, t.string]),
        mutuo_importo_richiesto_meno_ristrutturazione: t.union([t.number, t.null, t.string]),
    }),
]);
export const paperwork = t.type({
    id: t.string,
    artooId: t.string,
    currentStatus: t.type({ code: t.string, label: t.string }),
    assignedTo: t.type({ artooId: t.string, firstName: t.string, lastName: t.string }),
    createdBy: t.type({ artooId: t.string, firstName: t.string, lastName: t.string }),
    productType: productTypology,
    createdAt: t.string,
    transitionsHistory: t.array(transition),
    nextActions: t.array(nextAction),
    data: data,
    documentTypes: t.array(t.type({
        id: t.string,
        name: t.string,
        required: t.boolean,
        toBeFilled: t.boolean,
        toBeSigned: t.boolean,
        toBeUploaded: t.boolean,
    })),
    documents: t.array(documentation),
}, 'Paperwork');
