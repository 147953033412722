import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Badge } from '../atoms/Badge';
const useStyles = makeStyles()(() => ({
    mb8px: {
        marginBottom: '8px',
    },
    mt16px: {
        marginTop: '16px',
    },
    title: {
        color: '#6F797A',
    },
}));
export const CardDetail = ({ detail }) => {
    const { classes } = useStyles();
    return detail.map(({ section, title: sectionTitle }, key) => (_jsxs(Box, { children: [sectionTitle && (_jsx(Typography, { variant: "h3", sx: { mb: '8px' }, children: sectionTitle })), _jsx(Card, { elevation: 0, sx: { mb: '16px' }, children: section.map(({ title, badgeTitle, rows, hidden }, cardDetailKey) => hidden === true ? null : (_jsxs(CardContent, { children: [_jsx(Typography, { variant: "h4", className: classes.mb8px, children: title }), badgeTitle && (_jsx(Box, { className: classes.mb8px, children: _jsx(Badge, { title: badgeTitle, variant: "light" }) })), _jsx(Divider, {}), _jsx(Grid, { container: true, className: classes.mt16px, children: Object.entries(rows).map(([rowTitle, value], rowKey) => (_jsxs(Grid, { container: true, item: true, justifyContent: "space-between", className: classes.mb8px, children: [_jsx(Typography, { fontWeight: "500", className: classes.title, children: rowTitle }), value === null && _jsx(Badge, { title: "Da completare", variant: "grey" }), typeof value === 'string' && _jsx(Typography, { children: value }), typeof value === 'boolean' && value === false && (_jsx(CancelIcon, { fontSize: "small", sx: { color: '#B52528' } })), typeof value === 'boolean' && value === true && (_jsx(CheckCircleIcon, { fontSize: "small", sx: { color: '#00419D' } }))] }, rowKey))) })] }, cardDetailKey))) })] }, key)));
};
