import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from '@mui/material';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { $PaperworkProcess, $PaperworkProcessSlice } from '../../state/PaperworkProcessState';
import { CancelDigitalSignature } from '../molecules/CancelDigitalSignature';
import { CancelHandwrittenSignature } from '../molecules/CancelHandwrittenSignature';
import { DigitalSignature } from '../molecules/DigitalSignature';
import { HandwrittenSignature } from '../molecules/HandwrittenSignature';
const signPaperwork = (dispatch, signatureType) => () => {
    dispatch($PaperworkProcess.signPaperwork({ signatureType: signatureType }));
};
const cancelPaperworkSignature = (dispatch, signatureType) => () => {
    dispatch($PaperworkProcess.cancelSignature({ signatureType: signatureType }));
};
export const Signature = ({ paperworkDetails }) => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector($PaperworkProcessSlice.selector.loading).processPaperwork;
    return (_jsxs(Grid, { container: true, flexWrap: "nowrap", gap: "16px", children: [paperworkDetails.digitalSignature.isRevocable && (_jsx(Grid, { item: true, xs: 12, children: _jsx(CancelDigitalSignature, { isDisabled: isLoading, onClick: cancelPaperworkSignature(dispatch, 'digital') }) })), paperworkDetails.handwrittenSignature.isRevocable && (_jsx(Grid, { item: true, xs: 12, children: _jsx(CancelHandwrittenSignature, { isDisabled: isLoading, onClick: cancelPaperworkSignature(dispatch, 'handwritten') }) })), paperworkDetails.digitalSignature.isEnabled && !paperworkDetails.digitalSignature.isRequested && (_jsx(Grid, { item: true, xs: 6, children: _jsx(DigitalSignature, { isDisabled: isLoading, onClick: signPaperwork(dispatch, 'digital') }) })), paperworkDetails.handwrittenSignature.isEnabled && !paperworkDetails.handwrittenSignature.isRequested && (_jsx(Grid, { item: true, xs: 6, children: _jsx(HandwrittenSignature, { isDisabled: isLoading, onClick: signPaperwork(dispatch, 'handwritten') }) }))] }));
};
