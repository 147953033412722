import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Container, Drawer, Grid } from '@mui/material';
import { useState } from 'react';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { $PaperworkProcessSlice } from '../../state/PaperworkProcessState';
import { CardDetail } from '../molecules/CardDetail';
import { CqsFormMainSection } from '../organisms/CqsFormMainSection';
export const CqsDetail = ({ paperwork }) => {
    const detail = useAppSelector($PaperworkProcessSlice.selector.detail);
    const initialValues = useAppSelector($PaperworkProcessSlice.selector.initialValues);
    const [openCqsDrawer, setOpenCqsDrawer] = useState(false);
    return (_jsxs(Box, { children: [_jsx(Container, { maxWidth: "sm", children: _jsx(Grid, { container: true, item: true, justifyContent: "flex-end", children: _jsx(Button, { variant: "contained", onClick: () => {
                            setOpenCqsDrawer(true);
                        }, sx: { marginBottom: '16px' }, children: "Modifica" }) }) }), _jsx(Container, { maxWidth: "sm", sx: { marginTop: '16px' }, children: detail && _jsx(CardDetail, { detail: detail }) }), _jsx(Drawer, { open: openCqsDrawer, anchor: "right", children: _jsx(CqsFormMainSection, { paperworkId: paperwork.details.id, artooId: paperwork.details.artooId, initialValues: initialValues, onClose: () => {
                        setOpenCqsDrawer(false);
                    } }) })] }));
};
