import { format } from 'date-fns';
import { either } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { cqsTypeModel } from '../../models/CqsTypeModel';
export const cqsFormStateModelMapper = (paperworkDataModel) => {
    var _a, _b, _c;
    return {
        warrantStartDate: paperworkDataModel.data_firma_mandato || '',
        warrantEndDate: paperworkDataModel.data_scadenza_mandato || '',
        warrantEndLabel: paperworkDataModel.data_scadenza_mandato
            ? format(paperworkDataModel.data_scadenza_mandato, 'dd/MM/yyyy')
            : '',
        lendingDate: paperworkDataModel.data_erogazione_finanziamento || '',
        lendingPaymentMethod: paperworkDataModel.mezzo_di_pagamento_finanziamento || '',
        store: paperworkDataModel.store || '',
        storeInput: paperworkDataModel.store || '',
        channelInput: paperworkDataModel.rubinetto,
        channel: paperworkDataModel.classe_rubinetto,
        notes: paperworkDataModel.note || '',
        participant: {
            firstName: paperworkDataModel.nome,
            lastName: paperworkDataModel.cognome,
            residence: {
                address: paperworkDataModel.indirizzo_di_residenza || '',
                locationInput: paperworkDataModel.localita_di_residenza || '',
                location: {
                    name: paperworkDataModel.comune_di_residenza || '',
                    province: { shortName: paperworkDataModel.provincia_di_residenza || '' },
                },
                postalCode: paperworkDataModel.cap_di_residenza || '',
            },
            cityOfBirth: paperworkDataModel.comune_di_nascita || '',
            provinceOfBirth: paperworkDataModel.provincia_di_nascita || '',
            birthday: paperworkDataModel.data_di_nascita || '',
            gender: paperworkDataModel.sesso || '',
            fiscalCode: paperworkDataModel.codice_fiscale,
            identityDocument: {
                type: paperworkDataModel.tipo_di_documento || '',
                code: paperworkDataModel.n_di_documento || '',
                issuedBy: paperworkDataModel.rilasciato_da || '',
                placeOfIssue: paperworkDataModel.luogo_di_rilascio || '',
                issueDate: paperworkDataModel.data_di_rilascio || '',
                expiryDate: paperworkDataModel.data_di_scadenza || '',
            },
            contacts: {
                mobilePhone: paperworkDataModel.telefono_cellulare || '',
                landline: paperworkDataModel.telefono_fisso || '',
                email: paperworkDataModel.email,
            },
            citizenship: paperworkDataModel.cittadinanza || '',
            maritalStatus: paperworkDataModel.stato_civile || '',
            job: paperworkDataModel.professione || '',
            companyType: paperworkDataModel.tipo_di_azienda || '',
            employerVatNumber: paperworkDataModel.partita_iva_datore_di_lavoro || '',
            netIncome: paperworkDataModel.reddito_mensile_netto || '',
            monthlyInstallment: paperworkDataModel.numero_di_mensilita || '',
            lengthOfService: paperworkDataModel.anzianita_lavorativa || '',
            household: paperworkDataModel.componenti_nucleo_familiare || '',
            marketing: paperworkDataModel.consenso_invio_comunicazioni_promozionali_e_marketing || false,
            thirdParties: paperworkDataModel.consenso_cessione_dati_a_terzi || false,
        },
        cqs: {
            purpose: paperworkDataModel.cessione_del_quinto_finalita || '',
            type: pipe(paperworkDataModel.cessione_del_quinto_tipo_di_operazione, cqsTypeModel.decode, either.getOrElse(() => '')),
            employeeType: paperworkDataModel.cessione_del_quinto_tipo_di_dipendente || '',
            institute: paperworkDataModel.cessione_del_quinto_finanziaria || '',
            product: paperworkDataModel.cessione_del_quinto_prodotto || '',
            requestedAmount: paperworkDataModel.cessione_del_quinto_importo_richiesto || '',
            tan: ((_a = paperworkDataModel.cessione_del_quinto_taeg) === null || _a === void 0 ? void 0 : _a.toString()) || '',
            points: ((_b = paperworkDataModel.cessione_del_quinto_punti_applicati) === null || _b === void 0 ? void 0 : _b.toString()) || '',
            duration: ((_c = paperworkDataModel.cessione_del_quinto_durata_mesi) === null || _c === void 0 ? void 0 : _c.toString()) || '',
            installment: Number(paperworkDataModel.cessione_del_quinto_rata) || '',
            interest: Number(paperworkDataModel.cessione_del_quinto_montante) || '',
            interestLeft: Number(paperworkDataModel.cessione_del_quinto_montante_residuo) || '',
            interestDifference: Number(paperworkDataModel.cessione_del_quinto_delta_montante) || '',
        },
    };
};
