import { format } from 'date-fns';
import { array, option } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { numericFormatter } from 'react-number-format';
import { currencyFormatterRules } from '../utils/currencyFormatterRules';
const DATE_FORMAT = 'dd/MM/yyyy';
const hasInvestigationAsSubordinateProduct = (productName, products) => pipe(products, array.findFirst(product => product.name === productName), option.map(product => product.subordinateProducts), option.chain(subordinateProducts => pipe(subordinateProducts, array.findFirst(({ typology }) => 'Istruttoria' === typology))), option.isSome);
const isAnInAgreementInstitute = (instituteName) => !/^NON CONVENZIONE/.test(instituteName);
const hasInvestigationField = (products, productName, instituteName) => typeof instituteName === 'string' &&
    typeof productName === 'string' &&
    isAnInAgreementInstitute(instituteName) &&
    hasInvestigationAsSubordinateProduct(productName, products);
const hasProductField = (products, instituteName) => typeof instituteName === 'string' && isAnInAgreementInstitute(instituteName) && products.length > 0;
export const mortgageDetailStateModelMapper = (mortgageData, permissions, products = []) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10;
    return [
        { section: [{ title: 'Store di riferimento', rows: { Store: mortgageData.store || null } }] },
        {
            section: [
                {
                    title: 'Rubinetto',
                    hidden: !permissions.canViewChannel,
                    rows: { Rubinetto: mortgageData.rubinetto },
                },
            ],
        },
        {
            section: [
                {
                    title: 'Mandato',
                    rows: {
                        'Data firma mandato': mortgageData.data_firma_mandato
                            ? format(mortgageData.data_firma_mandato, DATE_FORMAT)
                            : null,
                        'Data scadenza mandato': mortgageData.data_scadenza_mandato
                            ? format(mortgageData.data_scadenza_mandato, DATE_FORMAT)
                            : null,
                    },
                },
            ],
        },
        {
            section: [
                {
                    title: 'Finanziamento',
                    rows: {
                        'Data erogazione finanziamento': mortgageData.data_erogazione_finanziamento
                            ? format(mortgageData.data_erogazione_finanziamento, DATE_FORMAT)
                            : null,
                        'Mezzo di pagamento finanziamento': (_a = mortgageData.mezzo_di_pagamento_finanziamento) !== null && _a !== void 0 ? _a : null,
                    },
                },
            ],
        },
        {
            section: [
                {
                    title: `${mortgageData.nome} ${mortgageData.cognome}`,
                    rows: {
                        'Indirizzo di residenza': (_b = mortgageData.indirizzo_di_residenza) !== null && _b !== void 0 ? _b : null,
                        'Località di residenza': (_c = mortgageData.localita_di_residenza) !== null && _c !== void 0 ? _c : null,
                        'CAP di residenza': (_d = mortgageData.cap_di_residenza) !== null && _d !== void 0 ? _d : null,
                        'Comune di nascita': (_e = mortgageData.comune_di_nascita) !== null && _e !== void 0 ? _e : null,
                        'Provincia di nascita': (_f = mortgageData.provincia_di_nascita) !== null && _f !== void 0 ? _f : null,
                        'Data di nascita': mortgageData.data_di_nascita
                            ? format(mortgageData.data_di_nascita, DATE_FORMAT)
                            : null,
                        Sesso: (_g = mortgageData.sesso) !== null && _g !== void 0 ? _g : '',
                        'Codice fiscale': mortgageData.codice_fiscale,
                        'Tipo di documento': (_h = mortgageData.tipo_di_documento) !== null && _h !== void 0 ? _h : null,
                        Cittadinanza: (_j = mortgageData.cittadinanza) !== null && _j !== void 0 ? _j : null,
                        'N° di documento': (_k = mortgageData.n_di_documento) !== null && _k !== void 0 ? _k : null,
                        'Rilasciato da': (_l = mortgageData.rilasciato_da) !== null && _l !== void 0 ? _l : null,
                        'Luogo di rilascio': (_m = mortgageData.luogo_di_rilascio) !== null && _m !== void 0 ? _m : null,
                        'Data di rilascio': mortgageData.data_di_rilascio
                            ? format(mortgageData.data_di_rilascio, DATE_FORMAT)
                            : null,
                        'Data di scadenza': mortgageData.data_di_scadenza
                            ? format(mortgageData.data_di_scadenza, DATE_FORMAT)
                            : null,
                    },
                },
                {
                    title: 'Contatti',
                    rows: {
                        'Telefono cellulare': mortgageData.telefono_cellulare,
                        'Telefono fisso': (_o = mortgageData.telefono_fisso) !== null && _o !== void 0 ? _o : null,
                        Email: mortgageData.email,
                    },
                },
                {
                    title: 'Dati reddituali',
                    rows: {
                        'Stato civile': (_p = mortgageData.stato_civile) !== null && _p !== void 0 ? _p : null,
                        Professione: (_q = mortgageData.professione) !== null && _q !== void 0 ? _q : null,
                        'Tipo di azienda': (_r = mortgageData.tipo_di_azienda) !== null && _r !== void 0 ? _r : null,
                        'P.IVA datore di lavoro': (_s = mortgageData.partita_iva_datore_di_lavoro) !== null && _s !== void 0 ? _s : null,
                        'Reddito mensile netto': mortgageData.reddito_mensile_netto
                            ? `${numericFormatter(mortgageData.reddito_mensile_netto.toString(), currencyFormatterRules)} €`
                            : null,
                        'Numero di mensilità': (_u = (_t = mortgageData.numero_di_mensilita) === null || _t === void 0 ? void 0 : _t.toString()) !== null && _u !== void 0 ? _u : null,
                        'Anzianità lavorativa': mortgageData.anzianita_lavorativa
                            ? `${mortgageData.anzianita_lavorativa} mesi`
                            : null,
                        'Componenti nucleo familiare': (_w = (_v = mortgageData.componenti_nucleo_familiare) === null || _v === void 0 ? void 0 : _v.toString()) !== null && _w !== void 0 ? _w : null,
                    },
                },
                {
                    title: 'Consensi',
                    rows: {
                        "Consenso per l'invio di comunicazioni promozionali e di marketing": (_x = mortgageData.consenso_invio_comunicazioni_promozionali_e_marketing) !== null && _x !== void 0 ? _x : false,
                        'Consenso per la cessione di dati a terzi': (_y = mortgageData.consenso_cessione_dati_a_terzi) !== null && _y !== void 0 ? _y : false,
                    },
                },
            ],
        },
        {
            section: [
                {
                    title: 'Mutuo',
                    rows: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ 'Tipologia di mutuo': (_z = mortgageData.mutuo_tipologia_di_mutuo) !== null && _z !== void 0 ? _z : null, Gestione: (_0 = mortgageData.mutuo_gestione) !== null && _0 !== void 0 ? _0 : null, Istituto: (_1 = mortgageData.mutuo_istituto) !== null && _1 !== void 0 ? _1 : null, Finalità: (_2 = mortgageData.mutuo_finalita) !== null && _2 !== void 0 ? _2 : null }, (hasProductField(products, mortgageData.mutuo_istituto) && {
                        Prodotto: (_3 = mortgageData.mutuo_prodotto) !== null && _3 !== void 0 ? _3 : null,
                    })), { 'Valore immobile': mortgageData.mutuo_valore_immobile
                            ? `${numericFormatter(mortgageData.mutuo_valore_immobile.toString(), currencyFormatterRules)} €`
                            : null, 'Importo richiesto': mortgageData.mutuo_importo_richiesto
                            ? `${numericFormatter(mortgageData.mutuo_importo_richiesto.toString(), currencyFormatterRules)} €`
                            : null, 'Capitale residuo': mortgageData.mutuo_capitale_residuo
                            ? `${numericFormatter(mortgageData.mutuo_capitale_residuo.toString(), currencyFormatterRules)} €`
                            : null }), (hasInvestigationField(products, mortgageData.mutuo_prodotto, mortgageData.mutuo_istituto) && {
                        'Importo istruttoria': mortgageData.mutuo_importo_istruttoria
                            ? `${numericFormatter(mortgageData.mutuo_importo_istruttoria.toString(), currencyFormatterRules)} €`
                            : null,
                    })), { 'Importo ristrutturazione': mortgageData.mutuo_importo_ristrutturazione
                            ? `${numericFormatter(mortgageData.mutuo_importo_ristrutturazione.toString(), currencyFormatterRules)} €`
                            : null, 'Importo di mediazione': mortgageData.mutuo_importo_di_mediazione
                            ? `${numericFormatter(mortgageData.mutuo_importo_di_mediazione.toString(), currencyFormatterRules)} €`
                            : null, 'Percentuale di mediazione': mortgageData.mutuo_percentuale_mediazione
                            ? `${mortgageData.mutuo_percentuale_mediazione}%`
                            : null, 'Tipo di immobile': (_4 = mortgageData.mutuo_tipo_di_immobile) !== null && _4 !== void 0 ? _4 : null, 'Stato ricerca immobile': (_5 = mortgageData.mutuo_stato_ricerca_immobile) !== null && _5 !== void 0 ? _5 : null, LTV: (_6 = mortgageData.mutuo_ltv) !== null && _6 !== void 0 ? _6 : null, 'Località immobile': (_7 = mortgageData.mutuo_localita_immobile) !== null && _7 !== void 0 ? _7 : null, Durata: (_8 = mortgageData.mutuo_durata_anni) !== null && _8 !== void 0 ? _8 : null, 'Tipo di tasso': (_9 = mortgageData.mutuo_tipo_di_tasso) !== null && _9 !== void 0 ? _9 : null }), (mortgageData.mutuo_istituto === 'Credito Valtellinese' && {
                        Spread: mortgageData.mutuo_spread ? `${mortgageData.mutuo_spread}%` : null,
                    })), { Note: (_10 = mortgageData.note) !== null && _10 !== void 0 ? _10 : null }),
                },
            ],
        },
    ];
};
