import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Button, Grid, Typography } from '@mui/material';
import { useCallback, useRef } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { $PaperworkProcess, $PaperworkProcessSlice } from '../../state/PaperworkProcessState';
import { FilePreview } from '../molecules/FilePreview';
const useStyles = makeStyles()(() => ({
    title: {
        color: '#3F484A',
    },
}));
const ACCEPTED_FORMATS = '.pdf,.PDF,.jpg,.jpeg,.jfif,.JPG,.JPE,.png,.PNG,.bmp,.xls,.xlsx,.csv,.ods,.doc,.docx,.txt,.rtf,.odt';
export const DocumentationItem = ({ title, documentTypeId, canUpload, canSign }) => {
    const { classes } = useStyles();
    const files = useAppSelector($PaperworkProcessSlice.selector.files)[documentTypeId];
    const isDownloadDisabled = useAppSelector($PaperworkProcessSlice.selector.loading).downloadFile;
    const dispatch = useAppDispatch();
    const fileInputRef = useRef(null);
    const handleButtonClick = () => {
        var _a;
        (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    const handleChange = useCallback((event) => {
        const fileList = Array.from(event.target.files || []);
        if (fileList.length === 0) {
            return;
        }
        dispatch($PaperworkProcess.uploadFiles({
            fileList: fileList,
            documentTypeId: documentTypeId,
        }));
        event.target.value = '';
    }, [dispatch, documentTypeId]);
    const handleSignature = useCallback((event) => {
        const fileList = Array.from(event.target.files || []);
        if (!fileList[0]) {
            return;
        }
        dispatch($PaperworkProcess.signDocument({
            file: fileList[0],
            documentTypeId: documentTypeId,
        }));
        event.target.value = '';
    }, [dispatch, documentTypeId]);
    return (_jsxs(Grid, { container: true, sx: { mb: '16px', mt: '16px' }, children: [_jsx(Grid, { container: true, justifyContent: "center", sx: { mb: '16px' }, children: _jsx(Typography, { sx: { textTransform: 'uppercase' }, variant: "h3", fontWeight: 500, className: classes.title, children: title }) }), _jsxs(Grid, { container: true, sx: {
                    '&>.MuiGrid-root': { mb: '8px' },
                    '&>.MuiGrid-root:last-child': { mb: '0px' },
                }, children: [!canUpload && (!files || files.length === 0) && (_jsx(Grid, { container: true, justifyContent: "center", children: _jsx(Typography, { children: "Compila la pratica per generare i documenti" }) })), files &&
                        files.map((file, index) => (_jsx(FilePreview, { file: file, isDownloadDisabled: isDownloadDisabled, onDownload: () => {
                                if (!file.id) {
                                    return;
                                }
                                dispatch($PaperworkProcess.downloadFile({
                                    documentId: file.id,
                                    fileMimeType: file.type,
                                }));
                            }, onDelete: canUpload
                                ? () => {
                                    dispatch($PaperworkProcess.deleteFile({
                                        documentTypeId: documentTypeId,
                                        documentation: file,
                                    }));
                                }
                                : undefined }, index)))] }), canUpload && (_jsxs(Grid, { container: true, justifyContent: "center", alignItems: "center", flexWrap: "nowrap", children: [_jsx(Button, { sx: { mt: '8px' }, variant: "contained", onClick: handleButtonClick, startIcon: _jsx(FileUploadOutlinedIcon, {}), children: "Carica" }), _jsx("input", { accept: ACCEPTED_FORMATS, style: { display: 'none' }, ref: fileInputRef, type: "file", onChange: handleChange })] })), canSign && (_jsxs(Grid, { container: true, justifyContent: "center", alignItems: "center", flexWrap: "nowrap", children: [_jsx(Button, { sx: { mt: '8px' }, variant: "contained", onClick: handleButtonClick, startIcon: _jsx(DrawOutlinedIcon, {}), children: "Firma" }), _jsx("input", { accept: ACCEPTED_FORMATS, style: { display: 'none' }, ref: fileInputRef, type: "file", onChange: handleSignature })] }))] }));
};
