import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from '@mui/material';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { $PaperworkProcessSlice } from '../../state/PaperworkProcessState';
import { Loader } from '../atoms/Loader';
import { DocumentationCard } from '../organisms/DocumentationCard';
import { Signature } from '../organisms/Signature';
export const DocumentationPage = () => {
    const isPageLoading = useAppSelector($PaperworkProcessSlice.selector.isPageLoading);
    const documentSection = useAppSelector($PaperworkProcessSlice.selector.documentSection);
    const paperwork = useAppSelector($PaperworkProcessSlice.selector.paperwork);
    return (_jsxs(_Fragment, { children: [isPageLoading && _jsx(Loader, {}), (paperwork === null || paperwork === void 0 ? void 0 : paperwork.details) && (_jsx(Grid, { sx: { mb: '16px' }, children: _jsx(Signature, { paperworkDetails: paperwork.details }) })), (paperwork === null || paperwork === void 0 ? void 0 : paperwork.details) && (_jsx(DocumentationCard, { title: "Documentazione", documentSection: documentSection, paperworkDetails: paperwork.details }))] }));
};
