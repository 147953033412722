import { documentTypesModelMapper } from './documentTypesModelMapper';
export const countUniqueDocuments = (documentation) => {
    const uniqueCategories = new Set();
    documentation.forEach(({ documentTypeId }) => {
        uniqueCategories.add(documentTypeId);
    });
    return uniqueCategories.size;
};
export const documentSectionStateModelMapper = (documentTypes, documentation) => {
    const requiredAndCompletedDocumentation = documentation.filter(({ required, uploaded, signed, filled }) => required && (uploaded || signed || filled));
    const signedDocumentation = documentation.filter(({ signed }) => signed);
    return {
        mandatoryDocuments: documentTypes.reduce((acc, documentType) => (documentType.required ? acc + 1 : acc), 0),
        uploadedDocuments: countUniqueDocuments(requiredAndCompletedDocumentation),
        documentTypes: documentTypesModelMapper(documentTypes),
        documentsToBeSigned: documentTypes.reduce((acc, documentType) => (documentType.toBeSigned ? acc + 1 : acc), 0),
        signedDocuments: countUniqueDocuments(signedDocumentation),
    };
};
