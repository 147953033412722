import * as t from 'io-ts';
export const SIGNATURE_STATUS_CODE = {
    REQUEST_DIGITAL_SIGN: 'requestDigitalSign',
    REQUEST_HANDWRITTEN_SIGN: 'requestHandwrittenSign',
    REVOKE_DIGITAL_SIGN_REQUEST: 'revokeDigitalSignRequest',
    REVOKE_HANDWRITTEN_SIGN_REQUEST: 'revokeHandwrittenSignRequest',
    DIGITAL_SIGN_REQUESTED: 'digitalSignRequested',
    HANDWRITTEN_SIGN_REQUESTED: 'handwrittenSignRequested',
};
export const signatureStatusCodeModel = t.keyof(SIGNATURE_STATUS_CODE);
