import { SIGNATURE_STATUS_CODE } from '../../../models/SignatureStatusCodeModel';
import { documentationModelMapper } from './documentationModelMapper';
import { nextActionMapper } from './nextActionMapper';
import { productTypologyModelMapper } from './productTypologyModelMapper';
export const paperworkModelMapper = (paperwork) => {
    const nextActionsCodes = paperwork.nextActions.map(({ code }) => code);
    return {
        productTypology: productTypologyModelMapper(paperwork.productType),
        data: paperwork.data,
        details: {
            id: paperwork.id,
            assignedTo: paperwork.assignedTo,
            createdAt: paperwork.createdAt,
            createdBy: paperwork.createdBy,
            artooId: paperwork.artooId,
            transitionsHistory: paperwork.transitionsHistory.map(transition => ({
                status: transition.status,
                triggeredAt: transition.triggeredAt,
                triggeredBy: transition.triggeredBy,
            })),
            nextActions: paperwork.nextActions.map(nextActionMapper),
            currentStatus: paperwork.currentStatus,
            documentTypes: paperwork.documentTypes,
            documentation: paperwork.documents.map(documentationModelMapper),
            digitalSignature: {
                isEnabled: nextActionsCodes.includes(SIGNATURE_STATUS_CODE.REQUEST_DIGITAL_SIGN),
                isRequested: paperwork.currentStatus.code === SIGNATURE_STATUS_CODE.DIGITAL_SIGN_REQUESTED,
                isRevocable: nextActionsCodes.includes(SIGNATURE_STATUS_CODE.REVOKE_DIGITAL_SIGN_REQUEST),
            },
            handwrittenSignature: {
                isEnabled: nextActionsCodes.includes(SIGNATURE_STATUS_CODE.REQUEST_HANDWRITTEN_SIGN),
                isRequested: paperwork.currentStatus.code === SIGNATURE_STATUS_CODE.HANDWRITTEN_SIGN_REQUESTED,
                isRevocable: nextActionsCodes.includes(SIGNATURE_STATUS_CODE.REVOKE_HANDWRITTEN_SIGN_REQUEST),
            },
        },
    };
};
