import { either } from 'fp-ts';
import { all, call, cancel, debounce, put, select, take, takeEvery, takeLeading } from 'typed-redux-saga';
import { isUserAuthorized } from '../../authorization/useAuthorization';
import { NotificationAction } from '../../store/notification/NotificationAction';
import { PERMISSION, SecurityState } from '../../store/security/SecurityState';
import { Notification } from '../../utils/notification';
import { SIGNATURE_STATUS_CODE } from '../models/SignatureStatusCodeModel';
import { $PaperworkProcess } from './PaperworkProcessState';
function* fetchChannels(paperworkService) {
    yield* put($PaperworkProcess.ChannelsRequested());
    const channelsEither = yield* call(paperworkService.getChannels());
    if (either.isLeft(channelsEither)) {
        yield* put($PaperworkProcess.ChannelsNotFetched());
        return new Error('channels not fetched');
    }
    yield* put($PaperworkProcess.ChannelsFetched({ channels: channelsEither.right }));
    return channelsEither.right;
}
function* fetchInstitutes(paperworkService, payload) {
    yield* put($PaperworkProcess.InstitutesRequested());
    const institutesEither = yield* call(paperworkService.getInstitutes({
        productTypology: payload.productTypology,
        channel: payload.channel,
        paperworkId: payload.paperworkId,
    }));
    if (either.isLeft(institutesEither)) {
        yield* put($PaperworkProcess.InstitutesNotFetched());
        return new Error('institutes not fetched');
    }
    yield* put($PaperworkProcess.InstitutesFetched({ institutes: institutesEither.right }));
    return institutesEither.right;
}
function* fetchProducts(paperworkService, payload) {
    yield* put($PaperworkProcess.ProductsRequested());
    const productsEither = yield* call(paperworkService.getProducts({
        institute: payload.institute,
        productTypology: payload.productTypology,
        channel: payload.channel,
        paperworkId: payload.paperworkId,
    }));
    if (either.isLeft(productsEither)) {
        yield* put($PaperworkProcess.ProductsNotFetched());
        return new Error('Products not fetched');
    }
    yield* put($PaperworkProcess.ProductsFetched({ products: productsEither.right }));
    return productsEither.right;
}
function* fetchStores(paperworkService, search) {
    yield* put($PaperworkProcess.StoresRequested());
    const storesEither = yield* call(paperworkService.getStores(search));
    if (either.isLeft(storesEither)) {
        yield* put($PaperworkProcess.StoresNotFetched());
        return;
    }
    yield* put($PaperworkProcess.StoresFetched({ stores: storesEither.right }));
}
function* fetchLocation(paperworkService, search) {
    yield* put($PaperworkProcess.LocationRequested());
    const locationEither = yield* call(paperworkService.getLocalities({ search }));
    if (either.isLeft(locationEither)) {
        yield* put($PaperworkProcess.LocationNotFetched());
        return;
    }
    yield* put($PaperworkProcess.LocationFetched({ participantPlaceOfResidence: locationEither.right }));
}
function* updatePaperwork(paperworkService, updatePaperworkCommand, paperworkId) {
    yield* put($PaperworkProcess.PaperworkUpdateRequested());
    const userPermissionList = yield* select(SecurityState.selector.permissionList);
    const canViewChannel = isUserAuthorized(userPermissionList)(PERMISSION.CAN_VIEW_CHANNEL_FIELD);
    function* updatePaperworkByProductTypology() {
        let paperworkEitherByTypology;
        switch (updatePaperworkCommand.productTypology) {
            case 'cqs':
                paperworkEitherByTypology = yield* call(paperworkService.updatePaperwork({
                    dataModel: updatePaperworkCommand.dataModel,
                    formData: updatePaperworkCommand.formData,
                    productTypology: updatePaperworkCommand.productTypology,
                    paperworkId: paperworkId,
                }));
                break;
            case 'mortgage':
                paperworkEitherByTypology = yield* call(paperworkService.updatePaperwork({
                    dataModel: updatePaperworkCommand.dataModel,
                    formData: updatePaperworkCommand.formData,
                    productTypology: updatePaperworkCommand.productTypology,
                    paperworkId: paperworkId,
                }));
                break;
        }
        return paperworkEitherByTypology;
    }
    const paperworkEither = yield* call(updatePaperworkByProductTypology);
    if (either.isLeft(paperworkEither)) {
        yield* put(NotificationAction.Push(Notification.error('Si è verificato un errore')));
        yield* put($PaperworkProcess.PaperworkNotUpdated());
        return;
    }
    yield* put(NotificationAction.Push(Notification.info('Dati salvati correttamente')));
    yield* put($PaperworkProcess.PaperworkUpdated({ paperwork: paperworkEither.right, permissions: { canViewChannel } }));
    const onSuccess = updatePaperworkCommand.onSuccess;
    if (onSuccess) {
        yield* call(onSuccess);
    }
}
function* fetchPaperwork(paperworkService, payload) {
    yield* put($PaperworkProcess.PaperworkRequested());
    const userPermissionList = yield* select(SecurityState.selector.permissionList);
    const canViewChannel = isUserAuthorized(userPermissionList)(PERMISSION.CAN_VIEW_CHANNEL_FIELD);
    const paperworkEither = yield* call(paperworkService.getPaperwork({ paperworkId: payload.paperworkId }));
    if (either.isLeft(paperworkEither)) {
        yield* put($PaperworkProcess.PaperworkNotFetched());
        return new Error('paperwork not fetched');
    }
    let products;
    if (paperworkEither.right.productTypology === 'mortgage') {
        if (!paperworkEither.right.data.mutuo_istituto) {
            return new Error('no institute available');
        }
        products = yield* call(paperworkService.getProducts({
            institute: paperworkEither.right.data.mutuo_istituto,
            productTypology: paperworkEither.right.productTypology,
            channel: paperworkEither.right.data.classe_rubinetto,
            paperworkId: payload.paperworkId,
        }));
        products = either.isLeft(products) ? undefined : products.right;
    }
    yield* put($PaperworkProcess.PaperworkFetched({
        products: products,
        paperwork: paperworkEither.right,
        permissions: { canViewChannel },
    }));
    return paperworkEither.right;
}
function* fillForm(paperworkService, paperwork, productTypology) {
    const userPermissionList = yield* select(SecurityState.selector.permissionList);
    const canViewChannel = isUserAuthorized(userPermissionList)(PERMISSION.CAN_VIEW_CHANNEL_FIELD);
    yield* put($PaperworkProcess.FormFillRequested());
    if (paperwork.productTypology === 'cqs') {
        const { channels, institutes, products } = yield* all(Object.assign(Object.assign(Object.assign({}, (canViewChannel && { channels: call(fetchChannels, paperworkService) })), (paperwork.data.rubinetto !== '' &&
            paperwork.data.cessione_del_quinto_finanziaria !== '' && {
            institutes: call(fetchInstitutes, paperworkService, {
                channel: paperwork.data.classe_rubinetto,
                productTypology: paperwork.productTypology,
                paperworkId: paperwork.details.id,
            }),
        })), (paperwork.data.rubinetto !== '' &&
            paperwork.data.cessione_del_quinto_finanziaria &&
            paperwork.data.cessione_del_quinto_finanziaria !== '' &&
            paperwork.data.istituto &&
            paperwork.data.istituto !== '' && {
            products: call(fetchProducts, paperworkService, {
                channel: paperwork.data.classe_rubinetto,
                productTypology: paperwork.productTypology,
                paperworkId: paperwork.details.id,
                institute: paperwork.data.istituto,
            }),
        })));
        if (channels instanceof Error || institutes instanceof Error || products instanceof Error) {
            yield* put(NotificationAction.Push(Notification.error('Si è verificato un errore')));
            yield* put($PaperworkProcess.FormNotFilled());
            return;
        }
        yield* put($PaperworkProcess.FormFilled({ paperwork: paperwork, productTypology: productTypology }));
    }
}
function* fetchCommissions(paperworkService, paperworkId) {
    yield* put($PaperworkProcess.CommissionsRequested());
    const commissionsEither = yield* call(paperworkService.getCommissions({ paperworkId: paperworkId }));
    if (either.isLeft(commissionsEither)) {
        yield* put($PaperworkProcess.CommissionsNotFetched({ error: commissionsEither.left }));
        return;
    }
    yield* put($PaperworkProcess.CommissionsFetched({ commissions: commissionsEither.right }));
}
function* processPaperwork(paperworkService, paperworkId, nextActionValue, onSuccess) {
    yield* put($PaperworkProcess.ProcessPaperworkRequested());
    const transitEither = yield* call(paperworkService.processPaperwork({ paperworkId: paperworkId, action: nextActionValue }));
    if (either.isLeft(transitEither)) {
        yield* put($PaperworkProcess.PaperworkNotProcessed());
        yield* put(NotificationAction.Push(Notification.error('Si è verificato un errore')));
        return;
    }
    yield* put(NotificationAction.Push(Notification.info('Pratica modificata con successo')));
    if (onSuccess) {
        yield* call(onSuccess);
    }
    yield* put($PaperworkProcess.PaperworkProcessed({ paperwork: transitEither.right }));
}
function* signPaperwork(paperworkService, paperworkId, signatureType) {
    yield* put($PaperworkProcess.SignatureRequested());
    const actions = new Map([
        ['digital', SIGNATURE_STATUS_CODE.REQUEST_DIGITAL_SIGN],
        ['handwritten', SIGNATURE_STATUS_CODE.REQUEST_HANDWRITTEN_SIGN],
    ]);
    const action = actions.get(signatureType);
    if (!action) {
        yield* put($PaperworkProcess.SignatureRequestFailed());
        return;
    }
    const signPaperworkEither = yield* call(paperworkService.processPaperwork({
        paperworkId: paperworkId,
        action: action,
    }));
    if (either.isLeft(signPaperworkEither)) {
        yield* put($PaperworkProcess.SignatureRequestFailed());
        yield* put(NotificationAction.Push(Notification.error('Si è verificato un errore')));
        return;
    }
    yield* put($PaperworkProcess.SignatureRequestDone({ paperwork: signPaperworkEither.right }));
}
function* cancelSignature(paperworkService, paperworkId, signatureType) {
    yield* put($PaperworkProcess.SignatureCancellationRequested());
    const actions = new Map([
        ['digital', SIGNATURE_STATUS_CODE.REVOKE_DIGITAL_SIGN_REQUEST],
        ['handwritten', SIGNATURE_STATUS_CODE.REVOKE_HANDWRITTEN_SIGN_REQUEST],
    ]);
    const action = actions.get(signatureType);
    if (!action) {
        yield* put($PaperworkProcess.SignatureNotCancelled());
        return;
    }
    const cancelPaperworkEither = yield* call(paperworkService.processPaperwork({
        paperworkId: paperworkId,
        action: action,
    }));
    if (either.isLeft(cancelPaperworkEither)) {
        yield* put($PaperworkProcess.SignatureNotCancelled());
        yield* put(NotificationAction.Push(Notification.error('Si è verificato un errore')));
        return;
    }
    yield* put($PaperworkProcess.SignatureCancelled({ paperwork: cancelPaperworkEither.right }));
}
function* signDocument(paperworkService, file, documentTypeId, paperworkId) {
    yield* put($PaperworkProcess.FileUploadRequested({ file: file, documentTypeId: documentTypeId }));
    const formData = new FormData();
    formData.append('document', file);
    const uploadFileEither = yield* call(paperworkService.signDocument({
        formData: formData,
        paperworkId: paperworkId,
        documentTypeId: documentTypeId,
    }));
    if (either.isLeft(uploadFileEither)) {
        yield* put($PaperworkProcess.FileNotUploaded({ file: file, documentTypeId: documentTypeId }));
        return;
    }
    yield* put($PaperworkProcess.FileUploaded({
        documentation: uploadFileEither.right,
        documentTypeId: documentTypeId,
    }));
    yield* call(fetchPaperwork, paperworkService, { paperworkId: paperworkId });
}
function* uploadFiles(paperworkService, fileList, documentTypeId, paperworkId) {
    yield* fileList.map(function* (file) {
        yield* put($PaperworkProcess.FileUploadRequested({ file: file, documentTypeId: documentTypeId }));
        const formData = new FormData();
        formData.append('documentTypeId', documentTypeId);
        formData.append('document', file);
        const uploadFileEither = yield* call(paperworkService.uploadFile({ formData: formData, paperworkId: paperworkId }));
        if (either.isLeft(uploadFileEither)) {
            yield* put($PaperworkProcess.FileNotUploaded({ file: file, documentTypeId: documentTypeId }));
            return;
        }
        yield* put($PaperworkProcess.FileUploaded({
            documentation: uploadFileEither.right,
            documentTypeId: documentTypeId,
        }));
        yield* call(fetchPaperwork, paperworkService, { paperworkId: paperworkId });
    });
}
function* fetchSigners(paperworkService, paperworkId) {
    yield* put($PaperworkProcess.SignersRequested());
    const signersEither = yield* call(paperworkService.getSigners({ paperworkId: paperworkId }));
    if (either.isLeft(signersEither)) {
        yield* put($PaperworkProcess.SignersNotFetched());
        return;
    }
    yield* put($PaperworkProcess.SignersFetched({ signers: signersEither.right }));
}
function* downloadFile(paperworkService, paperworkId, documentId, fileMimeType) {
    yield* put($PaperworkProcess.DownloadFileRequested());
    const downloadFileEither = yield* call(paperworkService.downloadFile({ paperworkId: paperworkId, documentId: documentId }));
    if (either.isLeft(downloadFileEither)) {
        yield* put($PaperworkProcess.FileNotDownloaded());
        return;
    }
    const fileBlob = new Blob([downloadFileEither.right], { type: fileMimeType });
    const fileURL = URL.createObjectURL(fileBlob);
    window.open(fileURL, '_blank');
    yield* put($PaperworkProcess.FileDownloaded());
}
function* deleteFile(paperworkService, documentation, documentTypeId, paperworkId) {
    if (!documentation.id) {
        yield* put($PaperworkProcess.FileDeleted({
            documentTypeId: documentTypeId,
            deletedDocument: documentation,
        }));
        return;
    }
    yield* put($PaperworkProcess.FileDeletionRequested({ documentTypeId: documentTypeId, documentToDelete: documentation }));
    const deleteFileEither = yield* call(paperworkService.deleteFile({ documentId: documentation.id, paperworkId: paperworkId }));
    if (either.isLeft(deleteFileEither)) {
        yield* put($PaperworkProcess.FileNotDeleted({ documentTypeId: documentTypeId, documentToDelete: documentation }));
        return;
    }
    yield* put($PaperworkProcess.FileDeleted({
        documentTypeId: documentTypeId,
        deletedDocument: documentation,
    }));
    yield* call(fetchPaperwork, paperworkService, { paperworkId: paperworkId });
}
export function* PaperworkProcessSaga(paperworkService) {
    yield* takeLeading($PaperworkProcess.start, function* (startCommand) {
        const paperworkId = startCommand.payload.paperworkId;
        if (!paperworkId) {
            return;
        }
        yield* put($PaperworkProcess.Started());
        const task = yield* all([
            yield* takeLeading($PaperworkProcess.processPaperwork, function* (command) {
                yield* call(processPaperwork, paperworkService, paperworkId, command.payload.nextActionValue, command.payload.onSuccess);
            }),
            yield* takeLeading($PaperworkProcess.fetchInstitutes, function* (fetchInstitutesCommand) {
                yield* call(fetchInstitutes, paperworkService, {
                    channel: fetchInstitutesCommand.payload.channel,
                    productTypology: fetchInstitutesCommand.payload.productTypology,
                    paperworkId: fetchInstitutesCommand.payload.paperworkId,
                });
            }),
            yield* takeLeading($PaperworkProcess.fetchProducts, function* (fetchProductsCommand) {
                yield* call(fetchProducts, paperworkService, {
                    channel: fetchProductsCommand.payload.channel,
                    paperworkId: fetchProductsCommand.payload.paperworkId,
                    institute: fetchProductsCommand.payload.institute,
                    productTypology: fetchProductsCommand.payload.productTypology,
                });
            }),
            yield* takeLeading($PaperworkProcess.fillForm, function* (fillFormCommand) {
                yield* call(fillForm, paperworkService, fillFormCommand.payload.paperwork, fillFormCommand.payload.productTypology);
            }),
            yield* takeLeading($PaperworkProcess.fetchChannels, function* () {
                yield* call(fetchChannels, paperworkService);
            }),
            yield* takeLeading($PaperworkProcess.updatePaperwork, function* (updatePaperworkCommand) {
                yield* call(updatePaperwork, paperworkService, updatePaperworkCommand.payload, paperworkId);
            }),
            yield* debounce(1000, $PaperworkProcess.fetchStores, function* (fetchStoreCommand) {
                yield* call(fetchStores, paperworkService, fetchStoreCommand.payload.search);
            }),
            yield* debounce(1000, $PaperworkProcess.fetchLocation, function* (fetchLocationCommand) {
                yield* call(fetchLocation, paperworkService, fetchLocationCommand.payload.search);
            }),
            yield* takeLeading($PaperworkProcess.signPaperwork, function* (signPaperworkCommand) {
                yield* call(signPaperwork, paperworkService, paperworkId, signPaperworkCommand.payload.signatureType);
            }),
            yield* takeLeading($PaperworkProcess.downloadFile, function* (downloadFileCommand) {
                yield* call(downloadFile, paperworkService, paperworkId, downloadFileCommand.payload.documentId, downloadFileCommand.payload.fileMimeType);
            }),
            yield* takeLeading($PaperworkProcess.cancelSignature, function* (cancelSignaturePaperworkCommand) {
                yield* call(cancelSignature, paperworkService, paperworkId, cancelSignaturePaperworkCommand.payload.signatureType);
            }),
            yield* takeLeading($PaperworkProcess.fetchSigners, function* () {
                yield* call(fetchSigners, paperworkService, paperworkId);
            }),
            yield* takeEvery($PaperworkProcess.deleteFile, function* (uploadFilesCommand) {
                yield* call(deleteFile, paperworkService, uploadFilesCommand.payload.documentation, uploadFilesCommand.payload.documentTypeId, paperworkId);
            }),
            yield* takeEvery($PaperworkProcess.signDocument, function* (uploadFilesCommand) {
                yield* call(signDocument, paperworkService, uploadFilesCommand.payload.file, uploadFilesCommand.payload.documentTypeId, paperworkId);
            }),
            yield* takeEvery($PaperworkProcess.uploadFiles, function* (uploadFilesCommand) {
                yield* call(uploadFiles, paperworkService, uploadFilesCommand.payload.fileList, uploadFilesCommand.payload.documentTypeId, paperworkId);
            }),
        ]);
        const paperwork = yield* call(fetchPaperwork, paperworkService, {
            paperworkId,
        });
        if (!(paperwork instanceof Error)) {
            yield all([call(fetchCommissions, paperworkService, paperwork.details.id)]);
        }
        yield* take($PaperworkProcess.stop);
        yield* cancel(task);
        yield* put($PaperworkProcess.Stopped());
    });
}
