import { array, either, eq, ord, string, taskEither } from 'fp-ts';
import { identity, pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import qs from 'qs';
import { AxiosClient } from '../../../common/clients/AxiosClient';
import { Session } from '../../../session/state/Session';
import { commissionItemModelMapper } from './mappers/commissionItemModelMapper';
import { cqsPaperworkMapper } from './mappers/cqsPaperworkMapper';
import { documentationModelMapper } from './mappers/documentationModelMapper';
import { localityModelMapper } from './mappers/localityModelMapper';
import { paperworkModelMapper } from './mappers/paperworkModelMapper';
import { paperworkSummaryModelMapper } from './mappers/paperworkSummaryModelMapper';
import { productModelMapper } from './mappers/productModelMapper';
import { productTypologyMapper } from './mappers/productTypologyMapper';
import { signerModelMapper } from './mappers/signerModelMapper';
import { storeModelMapper } from './mappers/storeModelMapper';
import { channel } from './models/Channel';
import { commissionItem } from './models/CommissionItem';
import { documentation } from './models/Documentation';
import { institute } from './models/Institute';
import { locality } from './models/Locality';
import { paperwork } from './models/Paperwork';
import { paperworkSummary } from './models/PaperworkSummary';
import { product } from './models/Product';
import { signer } from './models/Signer';
import { store } from './models/Store';
export class BazaarClient extends AxiosClient {
    paginate(paginationDto) {
        return { page: { size: paginationDto.perPage, offset: paginationDto.skip } };
    }
    sanitizeFilter(filter) {
        const filterWithValidValue = {};
        for (const [filterStateKey, filterStateValue] of Object.entries(filter)) {
            if (typeof filterStateValue === 'string' && filterStateValue !== '') {
                filterWithValidValue[filterStateKey] = filterStateValue;
            }
        }
        return { filter: Object.assign({}, filterWithValidValue) };
    }
    isHttpError(e) {
        return typeof e === 'object' && e !== null && 'response' in e;
    }
    uploadFile({ formData, paperworkId }) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.post(`api/v1/paperwork/${paperworkId}/documents`, formData, {
            headers: Object.assign(Object.assign({}, this.addBearerAuthorization(token)), { 'Content-type': 'multipart/form-data' }),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(documentation.decode), taskEither.map(documentationModelMapper));
    }
    signDocument({ formData, paperworkId, documentTypeId }) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.post(`api/v1/paperwork/${paperworkId}/document-types/${documentTypeId}/sign`, formData, {
            headers: Object.assign(Object.assign({}, this.addBearerAuthorization(token)), { 'Content-type': 'multipart/form-data' }),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(documentation.decode), taskEither.map(documentationModelMapper));
    }
    updatePaperwork({ formData, paperworkId, dataModel, productTypology }) {
        const token = Session.getToken();
        let payload = null;
        if (productTypology === 'cqs') {
            payload = cqsPaperworkMapper(dataModel, formData);
        }
        if (payload === null) {
            return taskEither.left(new Error('invalid payload provided'));
        }
        return pipe(taskEither.tryCatch(() => this.http.patch(`/api/v1/paperwork/${paperworkId}`, { data: Object.assign({}, payload) }, {
            headers: this.addBearerAuthorization(token),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(paperwork.decode), taskEither.map(paperworkModelMapper));
    }
    getPaperwork({ paperworkId }) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/paperwork/${paperworkId}`, {
            headers: this.addBearerAuthorization(token),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(paperwork.decode), taskEither.map(paperworkModelMapper));
    }
    getChannels() {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/channels`, {
            headers: this.addBearerAuthorization(token),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(t.array(channel).decode));
    }
    getProducts(getProductsDto) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/products`, {
            params: {
                rubinetto: getProductsDto.channel,
                institute: getProductsDto.institute,
                productTypology: productTypologyMapper(getProductsDto.productTypology),
                recordId: getProductsDto.paperworkId,
            },
            headers: this.addBearerAuthorization(token),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(u => pipe(t.array(product).decode(u), either.map(array.filter(({ name }) => name !== 'none')), either.map(array.uniq(pipe(string.Eq, eq.contramap((duplicatedProduct) => duplicatedProduct.name)))), either.map(array.sort(pipe(string.Ord, ord.contramap((productToSort) => productToSort.name)))))), taskEither.map(array.map(productModelMapper)));
    }
    getInstitutes(getInstitutesDto) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/institutes`, {
            params: {
                productTypology: productTypologyMapper(getInstitutesDto.productTypology),
                recordId: getInstitutesDto.paperworkId,
                rubinetto: getInstitutesDto.channel,
            },
            headers: this.addBearerAuthorization(token),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(t.array(institute).decode));
    }
    getStores(storeName) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/stores`, {
            headers: this.addBearerAuthorization(token),
            params: {
                name: storeName,
                sort: 'name',
            },
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(t.array(store).decode), taskEither.map(array.map(storeModelMapper)));
    }
    getLocalities(getLocalitiesDto) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/localities`, {
            params: {
                search: getLocalitiesDto.search,
                'filter[type]': 'city',
            },
            headers: this.addBearerAuthorization(token),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(t.array(locality).decode), taskEither.map(array.map(localityModelMapper)));
    }
    getCommissions(getCommissionsDto) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/paperwork/${getCommissionsDto.paperworkId}/commissions`, {
            headers: this.addBearerAuthorization(token),
        }), e => {
            if (this.isHttpError(e)) {
                return new Error('Non è stato possibile calcolare le provvigioni, controlla i dati inseriti.');
            }
            return new Error('Si è verificato un errore');
        }), taskEither.chain(response => pipe(response.data, t.array(t.array(commissionItem)).decode, either.map(array.map(array.map(commissionItemModelMapper))), taskEither.fromEither, taskEither.mapLeft(() => {
            return new Error('Si è verificato un errore');
        }))));
    }
    processPaperwork(transitDto) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.post(`/api/v1/paperwork/${transitDto.paperworkId}/transit`, { context: null, action: transitDto.action }, {
            headers: this.addBearerAuthorization(token),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(paperwork.decode), taskEither.map(paperworkModelMapper));
    }
    getSigners({ paperworkId }) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/paperwork/${paperworkId}/signers`, {
            headers: this.addBearerAuthorization(token),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(t.array(signer).decode), taskEither.map(signers => pipe(signers, array.map(signerModelMapper))));
    }
    getPaperworks({ pagination, filter, sort }) {
        const page = this.paginate(pagination);
        const filterQueryParams = filter ? this.sanitizeFilter(filter) : {};
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/paperwork?${qs.stringify(Object.assign(Object.assign(Object.assign({}, page), { sort }), filterQueryParams))}`, {
            headers: this.addBearerAuthorization(token),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(t.array(paperworkSummary).decode), taskEither.map(array.map(paperworkSummaryModelMapper)));
    }
    deleteFile(deleteFileDto) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.delete(`/api/v1/paperwork/${deleteFileDto.paperworkId}/documents/${deleteFileDto.documentId}`, {
            headers: this.addBearerAuthorization(token),
        }), identity));
    }
    downloadFile({ documentId, paperworkId }) {
        const token = Session.getToken();
        return pipe(taskEither.tryCatch(() => this.http.get(`api/v1/paperwork/${paperworkId}/documents/${documentId}/file`, {
            headers: Object.assign({}, this.addBearerAuthorization(token)),
            responseType: 'blob',
        }), identity), taskEither.map(response => response.data));
    }
}
