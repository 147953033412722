import { either } from 'fp-ts';
import { all, call, cancel, put, take, takeLeading } from 'typed-redux-saga';
import { PaginationDto } from '../../common/dto/PaginationDto';
import { $PaperworkList } from './PaperworkListState';
function* fetchPaperworkList(paperworkService, pagination, filter) {
    yield* put($PaperworkList.PaperworkListRequested());
    const paperworkListEither = yield* call(paperworkService.getPaperworks({ pagination: pagination, sort: '-createdAt', filter: filter }));
    if (either.isLeft(paperworkListEither)) {
        yield* put($PaperworkList.PaperworkListNotFetched());
        return;
    }
    yield* put($PaperworkList.PaperworkListFetched({
        paperworkList: paperworkListEither.right,
        pagination: pagination,
        filter: filter,
    }));
}
function* fetchNextPage(paperworkService, pagination, filter) {
    pagination.nextPage();
    yield* call(fetchPaperworkList, paperworkService, pagination, filter);
}
function* fetchPreviousPage(paperworkService, pagination, filter) {
    if (pagination.canGoToPreviousPage()) {
        pagination.previousPage();
        yield* call(fetchPaperworkList, paperworkService, pagination, filter);
    }
}
function* fetchFirstPage(paperworkService, pagination, filter) {
    pagination.firstPage();
    yield* call(fetchPaperworkList, paperworkService, pagination, filter);
}
export function* PaperworkListSaga(paperworkService) {
    const pagination = new PaginationDto(10);
    yield* takeLeading($PaperworkList.start, function* () {
        yield* put($PaperworkList.Started());
        const task = yield* all([
            yield* takeLeading($PaperworkList.fetchFirstPage, command => fetchFirstPage(paperworkService, pagination, command.payload.filter)),
            yield* takeLeading($PaperworkList.fetchNextPage, command => fetchNextPage(paperworkService, pagination, command.payload.filter)),
            yield* takeLeading($PaperworkList.fetchPreviousPage, command => fetchPreviousPage(paperworkService, pagination, command.payload.filter)),
        ]);
        yield* call(fetchPaperworkList, paperworkService, pagination);
        yield* take($PaperworkList.stop);
        yield* cancel(task);
        yield* put($PaperworkList.Stopped());
    });
}
